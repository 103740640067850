import { render, staticRenderFns } from "@/components/job/JobPreview.pug?vue&type=template&id=836ecb3e&scoped=true&lang=pug&"
import script from "./JobPreview.vue?vue&type=script&lang=ts&"
export * from "./JobPreview.vue?vue&type=script&lang=ts&"
import style0 from "./JobPreview.vue?vue&type=style&index=0&id=836ecb3e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "836ecb3e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VChip,VCol,VContainer,VProgressLinear,VRow})
