




import Vue from 'vue';
import AssetDetailsTable from '@/components/asset/AssetDetailsTable.vue';
import JobDetailsTable from '@/components/job/JobDetailsTable.vue';
import ProductDetailsTable from '@/components/product/ProductDetailsTable.vue';
import { mapState } from 'vuex';

export default Vue.extend({
  name: 'job-preview',
  components: {
    AssetDetailsTable,
    JobDetailsTable,
    ProductDetailsTable,
  },
  computed: {
    ...mapState({
      job: (state: any) => state.job.job,
    }),
  },
  props: {
    admin: Boolean,
    close: Function,
    errorMessage: String,
    loading: Boolean,
  },
});
